import { api } from "@/instances/http";

class InvoiceTypesService {
  findMany(params) {
    return api
      .post("/invoices/types/findMany", { ...params })
      .then(response => {
        return response.data;
      });
  }
  findUnique(params) {
    return api
      .post("/invoices/types/findUnique", { ...params })
      .then(response => {
        return response.data;
      });
  }
  findFirst(params) {
    return api
      .post("/invoices/types/findFirst", { ...params })
      .then(response => {
        return response.data;
      });
  }
  update(params) {
    return api.post("/invoices/types/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/invoices/types/create", { ...params }).then(response => {
      return response.data;
    });
  }
  delete(params) {
    return api.post("/invoices/types/delete", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new InvoiceTypesService();
