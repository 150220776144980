<template>
  <v-data-table
      :headers="headers"
      :items="viewData"
      :search="search"
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
  >
  </v-data-table>
</template>

<script>
import moment from "moment/moment";
import InvoiceTypesServices from "@/services/invoice.types.service";

export default {
  props: ["data", "loading", "search"],
  name: "CreatedBonuses",
  data: () => ({
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Тип", value: "type" },
      { text: "Сумма", value: "amount" },
      { text: "Сумма USDT", value: "amountUsdt" },
      { text: "Валюта в фиате", value: "currency" },
      { text: "Ссылка на чек", value: "fileUrl" },
      { text: "Дата создания", value: "createdAt" }
    ],
    currencies: [
      { id: 1, value: "USD" },
      { id: 2, value: "EUR" },
      { id: 3, value: "RUB" }
    ],
    types: []
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        const amount = (item.amount / 100).toFixed(2);
        const amountUsdt = (item.amountUsdt / 100).toFixed(2);
        const currency = this.currencies[item.currency - 1].value;
        const type = this.types.find(t => t.id === item.type).value;
        return {
          ...item,
          createdAt: createdAt,
          amount: amount,
          amountUsdt: amountUsdt,
          currency: currency,
          type: type
        };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  async mounted() {
    await InvoiceTypesServices.findMany().then(data => {
      console.log(data);
      this.types = data;
    });
    console.log(this.types);
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
